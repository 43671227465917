/* Platform.css */

:root {
  --primary-err-color: #ff2929;
  --std-border: 1px solid;
  --hover-bg: rgba(0, 0, 0, 0.1);
  --secondary-bg-color: #f2f2f2;
  --disable-bg-color: #606060;
  --primary-correct-color: #27ae60;
}

html[data-theme="penta"] {
  --primary-theme-color: rgba(93, 188, 210);
  --primary-theme-color-half: rgba(93, 188, 210, 0.5);
  --primary-theme-color-08: rgba(93, 188, 210, 0.8);
  --primary-font-color: white;
  --hover-bg: rgba(93, 188, 210, 0.2);
  --light-bg-color: rgba(93, 188, 210, 0.3);
  --dark-bg-color: #004884;
  --dark-bg-color-07: rgba(0, 42, 132, 0.7);
  --std-border: 0;
  --chat-bubble-color: rgba(1, 157, 154, 0.2);
  --chat-bubble-color-selected: rgba(1, 157, 154, 0.6);
  --dashboard-label-bg: rgba(234, 246, 252, 0.5);
  --sidebar-light-bg: rgb(221, 244, 250);
  --light-hover-bg: rgb(221, 244, 250);
}

html[data-theme="penguin"] {
  --primary-theme-color: rgba(35, 153, 229);
  --primary-theme-color-half: rgba(35, 153, 229, 0.5);
  --primary-theme-color-08: rgba(35, 153, 229, 0.8);
  --primary-font-color: white;
  --hover-bg: rgba(35, 153, 229, 0.2);
  --light-hover-bg: rgba(35, 153, 229, 0.1);
  --light-bg-color: rgba(35, 153, 229, 0.3);
  --dark-bg-color: rgba(35, 153, 229);
  --dark-bg-color-07: rgba(35, 153, 229, 0.7);
  --chat-bubble-color: rgba(35, 153, 229, 0.2);
  --chat-bubble-color-selected: rgba(35, 153, 229, 0.4);
  --primary-err-color: #ff4d4d;
  --primary-correct-color: #27ae60;
  --std-border: 0;
  --dashboard-label-bg: rgba(234, 246, 252, 0.5);
  --sidebar-light-bg: rgb(221, 244, 250);
}

h1.resolve-chat {
  padding: 5%;
  text-align: center;
}

.header5 {
  font-size: 1rem;
}

.main-nurse {
  height: 100%;
}

button.ui.icon.button.tabButton {
  width: 100%;
  margin: 0;
  border-radius: 0;
  background-color: var(--sidebar-light-bg);
  color: var(--primary-theme-color);
}

button.ui.active.icon.button.tabButton {
  background-color: var(--primary-theme-color);
  color: var(--primary-font-color);
}

button.ui.icon.button.tabButton:hover:not(.active) {
  background-color: var(--primary-theme-color-half);
}

.MainScreen .ui.grid .page {
  padding: 0;
  width: calc(100% - 40px);
  height: 100%;
  /* height: calc(100vh - 40px); */
}

.MainScreen .ui.grid .page.hide-sidebar {
  width: 100%;
}

.MainScreen > .ui.grid {
  padding: 1.5px 0px 0px 0px;
  margin: 0;
  overflow: hidden;
  /* height: 93vh; */
}

.MainScreen .ui.grid.left-side-menu .column.tab {
  padding: 0;
  width: 40px;
  /* border-right: 0.5px solid var(--primary-theme-color); */
  background-color: var(--light-hover-bg);
  min-height: calc(100vh - 40px);
  max-height: 100vh;
}

.MainScreen .topbar-menu.ui.menu {
  background-color: var(--primary-theme-color);
  margin: 0;
  height: 40px;
  border-bottom: var(--std-border);
}

.MainScreen .topbar-menu.ui.menu .ui.button {
  background-color: var(--primary-theme-color);
  color: var(--primary-font-color);
}

.MainScreen .topbar-menu.ui.menu h2 {
  color: var(--primary-font-color);
  cursor: pointer;
}

.MainScreen .topbar-menu.ui.menu .right.item h3 {
  margin: 0;
  color: var(--primary-font-color);
}

.DoctorScheduleView .dateHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1%;
}

.DoctorScheduleView .content {
  padding-left: 1%;
  padding-right: 1%;
}

.DoctorScheduleView .calendarContent {
  height: 77vh;
  max-height: 77vh;
  overflow-y: auto;
  overflow-x: hidden;
  border-bottom: solid var(--primary-theme-color) 1px;
}

.DoctorScheduleView .calendarContent::-webkit-scrollbar {
  /* width: 0px; */
  /* background: transparent;  */
  background: var(--primary-theme-color);
  opacity: 0.2;
  /* background: rgba(93,188,210, 0.2); */
}

.DoctorScheduleView .calendarContent::-webkit-scrollbar-thumb {
  /* background: rgba(93,188,210, 0.8); */
  background: var(--primary-theme-color);
  opacity: 0.8;
}

.DoctorScheduleView .dateHeader .button {
  background-color: var(--primary-theme-color);
  opacity: 0.5;
}

.mod-select-appointment.ui.modal .header {
  display: flex;
  justify-content: space-between;
}

.FormAnalytics .triage-level-list {
  background-color: var(--light-bg-color);
  height: 92px;
  width: 117px;
  border-radius: 10px;
  padding: 16px;
  color: gray;
  cursor: pointer;
}

.FormAnalytics .triage-level-list:hover {
  background-color: var(--primary-theme-color-half);
}

.FormAnalytics .triage-level-list.selected {
  background-color: var(--primary-theme-color);
}

.FormAnalytics .headers {
  background-color: var(--primary-theme-color);
  color: white;
}

.FormAnalytics .items-list {
  padding-top: 0px !important;
  padding-right: 0px !important;
  height: 80vh;
  overflow: auto;
}

.FormAnalytics .items-list .ui.form:hover {
  background-color: var(--primary-theme-color-half);
  opacity: 0.75;
  cursor: pointer;
}

.FormAnalytics .items-list .ui.form.selected {
  background-color: var(--primary-theme-color-half);
}

.FormAnalytics .assessment-form {
  background-color: var(--light-bg-color);
  height: 80vh;
  overflow: auto;
  padding: 0 !important;
}

.FormAnalytics .assessment-form .answer {
  padding: 10px;
  background: white;
  border-radius: 5px;
  margin-bottom: 3px;
  padding-top: 5px;
}

.FormAnalytics .primary {
  color: var(--primary-theme-color);
}

.register-new-user {
  display: flex;
  height: 100%;
  margin-top: 20px;

  .profile-image-section {
    height: 100%;
    flex-basis: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .profile-image {
      width: 120px;
      margin: 20px 0px;
    }
    .profile-button {
      margin: 20px 0px;
    }
    .profile-text {
      color: #4f4f4f;
    }
  }

  .profile-info {
    flex-basis: 80%;
    padding: 0px 100px 0px 44px;
    margin-bottom: 250px;
    border-left: 0.5px solid #b6b6b6;

    label.reg {
      width: 180px !important;
      color: #828282 !important;
      font-size: 15px !important;
    }
    .reg {
      width: 400px !important;
    }
    .last-div {
      display: flex;
      // justify-content: flex-end;
      padding-left: 500px;
    }
  }
}

.no-oa-channel {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  .title-first {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .title-second {
    font-size: 24px;
    font-weight: bold;
  }

  .last-div {
    margin-top: 100px;
  }
}

// OA Form
.oa-form-custom {
  .ui.button {
    &.primary {
      background-color: var(--primary-theme-color);
      width: 150px;
      color: white;
      border-radius: 6px;
    }
    &.second {
      background-color: #48a1d9;
      border-radius: 8px;
      padding: 13px 55px;
    }
    &.back {
      background-color: #f2994a;
    }
  }

  .primary {
    color: var(--primary-theme-color);
    &.ui.input > input {
      color: var(--primary-theme-color);
    }
  }

  .ui.input.error > input {
    background-color: #fff6f6;
    border-color: #e0b4b4;
    color: #9f3a38;
    box-shadow: none;
  }

  .nav-bar {
    background-color: var(--light-bg-color);
    color: var(--primary-theme-color);
    .ui.items {
      .item {
        padding: 14px 0 14px 20px;
        margin: 0;
        font-weight: bold;
        cursor: pointer;
        i {
          margin-right: 10px;
        }
        &.is_active {
          background-color: var(--primary-theme-color);
          color: white;
        }
      }
    }
  }
  .font-error {
    display: flex;
    justify-content: flex-end;
    color: var(--primary-err-color);
  }
}

// Step-Progress
.steps.oa {
  list-style: none;
  margin: 0;
  padding: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
  color: darken(#e6e6e6, 33%);
  height: 4rem;

  > .step {
    position: relative;
    display: table-cell;
    text-align: center;
    font-size: 1rem;
    color: #dce1e4;
    font-weight: bold;

    &:before {
      content: attr(data-step);
      display: block;
      margin: 0 auto;
      background: #e7eaf3;
      border: 2px solid #e6e6e6;
      color: #b6bbbf;
      width: 3.2rem;
      height: 3.2rem;
      text-align: center;
      line-height: 2.7rem;
      border-radius: 100%;
      position: relative;
      z-index: 1;
      font-weight: 700;
      font-size: 1.5rem;
      margin-bottom: 0.6rem;
    }
    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 0.125rem;
      top: 1.5rem;
      left: 50%;
      border-top: 2.3px dashed #e7eaf3;
    }
    &:last-child:after {
      display: none;
    }
    &.is-complete {
      color: #5a5a5a;

      &:before {
        // content: "\2713";
        color: var(--primary-theme-color);
        // background: var(--primary-theme-color-half);
        border: 2px solid var(--primary-theme-color);
        transition: 0.6s ease-out;
        background: white;
      }
      &:after {
        // background: var(--primary-theme-color);
        border-top: 2px dashed var(--primary-theme-color);
        transition: 1s ease-out;
      }
    }
    &.is-active {
      font-size: 1.15rem;
      color: #131313;
      &:before {
        color: #fff;
        border: 2px solid var(--primary-theme-color);
        background: var(--primary-theme-color);
        margin-bottom: 0.6rem;
        transition: 1s ease-out;
      }
    }
  }
}

// Notification
.card-noti-oa {
  position: absolute;
  border-radius: 13px;
  padding: 26px;
  background-color: white;
  right: 3.4em;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.15);
  width: 400px;
  top: 2.4em;
  .btn-remove {
    display: flex;
    justify-content: center;
    margin-top: 35px;
    margin-bottom: -10px;
    color: var(--primary-theme-color);
  }
  .title {
    font-size: 14px;
    font-weight: bold;
    color: #434040;
  }
}

.ui.menu .ui.dropdown .menu > #create_oa {
  color: var(--primary-theme-color) !important;
}

// SettingOA
.setting-oa {
  .ui.grid .tab {
    background-color: white;
  }
  .profile-image {
    margin-top: 0px;
  }
  .ui.form .field {
    margin-bottom: 15px;
  }

  .agency-code {
    div {
      display: flex;
      align-items: baseline;
      .ui.input {
        width: 287px !important;
        margin-left: 13px;
        margin-right: 25px;
      }
    }
  }

  .ui.button.btn-green {
    border: 1px solid #27ae60;
    border-radius: 9px;
    cursor: pointer;
    color: #27ae60;
    background-color: white;
    margin-left: 15px;
  }

  .ReactTable .-odd {
    background-color: white;
  }
  .filter-input {
    .ui.input input {
      border-color: var(--primary-theme-color);
    }
    .ui.dropdown {
      border-color: var(--primary-theme-color);
    }
  }
}

.setting-oa,
.main-doctor-schedule {
  .ui.menu.fluid,
  .ui.vertical.menu {
    &.fluid {
      width: 120% !important;
      border-radius: 0;
      box-shadow: none;
    }
    .item {
      padding: 15px;
      font-size: 14px;
      border-radius: 0;
      flex-direction: initial;
      display: flex;
      i {
        margin-right: 10px;
      }
    }
    .active.item {
      background: rgba(31, 120, 180, 0.8);
      color: white;
      border-radius: 0;
    }
  }
  .ui.grid .tab {
    background-color: white;
  }
}

// Studio {
.main-studio {
  .list-create {
    // overflow: auto;
    margin-top: -10px;
    transform: scale(0.9);
    .group-item {
      height: 94vh;
      overflow-y: auto;
      padding-bottom: 10px;
    }
    .header-list {
      background-color: var(--primary-theme-color);
      color: white;
      padding: 8px;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ui.circular.button {
        background-color: white;
        i {
          color: var(--primary-theme-color);
        }
      }
    }
  }
}

// Doctor Schedule
.main-doctor-schedule {
  .list-create {
    // overflow: auto;
    margin-top: -10px;
    transform: scale(0.9);
    .group-item {
      height: 94vh;
      overflow-y: auto;
      padding-bottom: 10px;
    }
    .header-list {
      background-color: var(--primary-theme-color);
      color: white;
      padding: 8px;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ui.circular.button {
        background-color: white;
        i {
          color: var(--primary-theme-color);
        }
      }
    }
    .item {
      background-color: white;
      padding: 15px 10px;
      border-bottom: 1px solid #bdbdbd;
      cursor: pointer;
      // color: #4f4f4f;
      .primary {
        color: var(--primary-theme-color);
        &.number {
          margin: 0 5px;
        }
      }
      &.active {
        background-color: var(--light-bg-color);
      }
    }
  }
  .ui.form.table-management {
    .label {
      width: 125px;
    }
  }

  .ui.button {
    &.primary {
      background-color: var(--primary-theme-color);
      color: white;
    }
    &.grey {
      background: white;
      border: 1px solid #e0e0e0;
      color: #e0e0e0;
    }
    &.light {
      background-color: var(--light-bg-color);
      color: white;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .font-primary {
    color: var(--primary-theme-color);
  }

  .list-date {
    display: flex;
    .group-item {
      display: flex;
      align-items: flex-end;
      margin-left: 10px;
      .item {
        display: grid;
        margin-left: 7px;
        margin-right: 2px;
        color: #828282;
        div:nth-child(1) {
          font-weight: bold;
        }
      }
      .ui.checkbox label:before {
        border: 1px solid var(--primary-theme-color);
      }
    }
  }
}

.time-custom-react-picker {
  width: 99px;
  .react-time-picker__wrapper {
    border-radius: 3px;
    height: 37.8px;
    border: 1px solid #bdbdbd;
    overflow: hidden;
    .react-time-picker__inputGroup {
      display: flex;
      align-items: center;
      justify-content: center;
      .react-time-picker__inputGroup__input {
        width: 10px;
        height: 17px;
        border: 0;
      }
      .react-time-picker__inputGroup__divider {
        color: var(--primary-theme-color);
      }
    }
  }
}

// QAChat

.qa-chat-table,
.qa-chat-modal {
  .font-primary {
    color: var(--primary-theme-color);
  }
  .font-blue,
  .ui.input.font-blue input {
    color: #2f80ed;
  }
  .ui.button.btn-primary {
    background-color: var(--primary-theme-color) !important;
    color: white;
  }
}
.qa-chat-table {
  // padding: 10px;

  * {
    scrollbar-width: thin;
    &:-webkit-scrollbar {
      width: 6px;
    }
    /* webkit browsers */
    &::-webkit-scrollbar {
      width: 6px;
    }
  }

  .ui.button {
    &.primary {
      background-color: var(--primary-theme-color);
      width: 150px;
      color: white;
      border-radius: 6px;
    }

    &.back {
      background-color: #f2994a;
    }
  }

  .profile-form {
    padding: 5px;
    overflow-y: auto;
    height: calc(100vh - 82px);
    .header {
      padding: 5px;
      margin-bottom: -10px;
      // margin-top: 10px;
      margin-left: 5px;
    }
    .inline.field {
      display: flex;
      align-items: center;
    }
  }
  .center-header {
    display: grid;
    grid-template-columns: 25% 75%;
    background: #f2f2f2;
    padding: 2px 10px 1px;
    .ui.button {
      padding: 7px 20px;
      border-radius: 8px;
      margin: 3px 4px;
      margin-bottom: 4px;
      border: 2px solid var(--primary-theme-color);
      &.finish-treatment {
        color: #eb5757;
        border: 2px solid #eb5757;
        background-color: white;
      }
      &.edit-appointment,
      &.create-appointment {
        background-color: white !important;
      }
      &.teleconsult {
        border: 2px solid #27ae60;
        background-color: #27ae60;
      }
      border-width: 1px !important;
    }
    .ui.dropdown {
      padding: 7px 20px;
      border-radius: 8px;
      margin: 3px 4px;
      margin-bottom: 4px;
      min-height: auto !important;
      height: 2.1em;
      width: 100%;
      .text {
        overflow: hidden;
        height: 17px;
        line-height: 20px;
        margin-top: -3px;
      }
      i {
        margin-top: -13px !important;
      }
    }
  }
  .form-label-primary {
    &.ui.form .field > label {
      color: var(--primary-theme-color);
      font-weight: bold;
    }
  }
  .card-patient-panel {
    .sub.header {
      margin-bottom: 30px;
      &.left-align {
        margin: 5px;
        line-height: 2.2em;
      }
    }
  }
  .card-vital-sign {
    .ReactTable .rt-thead {
      flex: unset;
    }
    .rthfc .rt-th,
    .rthfc.-sp .rthfc-th-fixed {
      background-color: unset;
    }
  }
  .badge {
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
  }
}
.ui.modal {
  .modal-profile {
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    .image {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        border-radius: 50%;
        width: 60%;
      }
    }
    .detail {
      div {
        line-height: 2.2em;
        font-weight: bold;
      }
    }
  }
  .modal-profile-button {
    .ui.button {
      &.primary {
        background-color: var(--primary-theme-color);
        width: 150px;
        color: white;
        border-radius: 6px;
      }

      &.back {
        background-color: #f2994a;
      }
    }
  }
}

.chat-classify-list {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 175px);

  // grid-template-columns: 115px auto;
  .left-list {
    // height: 79vh;
    overflow-y: auto;
    padding-bottom: 0.5em;
    padding-top: 2px;
    padding-right: 3px;
  }

  .classify-group {
    width: 195px;
    min-height: 65px;
    border-radius: 8px;
    background: var(--primary-theme-color);
    padding: 8px;
    margin-bottom: 5px;
    color: white;
    font-weight: "bold";
    cursor: pointer;
    // margin-right: 85px;
    &.is_active {
      background: #0072bc;
      .icon-overlay {
        color: #0072bc !important;
      }
    }
    transition: width 0.3s;
    .content {
      width: 85%;
      // height: 38px;
      overflow: hidden;
      font-weight: bold;
    }
    .icon-overlay {
      position: absolute;
      left: 0px;
      top: 0px;
      transform: scale(0.8);
      color: var(--primary-theme-color) !important;
    }
  }
  .items {
    padding: 0 3px 8px 3px;
    width: 100%;
    transition: 0.3s ease-out;
    overflow-y: auto;
    padding-bottom: 0.9em;

    .item {
      padding: 10px 10px 20px 5px;
      border-radius: 3px;
      cursor: pointer;
      border-bottom: 1px solid #f2f2f2;
      &.active {
        background-color: rgba(114, 246, 189, 0.3) !important;
      }
      &:hover {
        background-color: rgba(135, 152, 145, 0.08);
      }
      div {
        margin-bottom: 5px;
      }
      label {
        font-weight: bold;
        color: black;
      }
    }
  }

  &.full-screen {
    .classify-group {
      width: 0;
      display: none;
    }
    .classify-group:nth-child(1) {
      height: 37px;
      min-height: auto;
      display: block;
      position: absolute;
      width: 92%;
      background-color: var(--primary-theme-color);
      .content {
        width: auto;
        display: flex;
        height: 17px;
      }
    }
    .items {
      margin-top: 47px;
      padding-left: 0;
    }
  }
}

.menu-patient-info {
  width: 50px;
  border-right: 1px solid #bdbdbd;
  height: calc(100vh - 82px);
  .ui.button {
    width: 100%;
    margin: 0;
    border-radius: 0;
    // background-color: var(--sidebar-light-bg);
    background-color: white;
    // color: var(--primary-theme-color);
    color: #4f4f4f;
    padding: 15px;
    i {
      font-size: 17px;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
    &.active {
      background-color: var(--sidebar-light-bg);
    }
  }
}
