:root {
  --primary-err-color: #ff2929;
  --std-border: 1px solid;
  --hover-bg: rgba(0, 0, 0, 0.1);
  --secondary-bg-color: #f2f2f2;
  --disable-bg-color: #606060;
}

html[data-theme="penta"] {
  --primary-theme-color: rgba(93, 188, 210);
  --primary-theme-color-half: rgba(93, 188, 210, 0.5);
  --primary-font-color: white;
  --hover-bg: rgba(93, 188, 210, 0.2);
  --light-bg-color: rgba(93, 188, 210, 0.3);
  --dark-bg-color: #004884;
  --dark-bg-color-07: rgba(0, 42, 132, 0.7);
  --std-border: 0;
  --chat-bubble-color: rgba(1, 157, 154, 0.2);
  --chat-bubble-color-selected: rgba(1, 157, 154, 0.6);
}

html[data-theme="penguin"] {
  --primary-theme-color: rgba(35, 153, 229);
  --primary-theme-color-half: rgba(35, 153, 229, 0.5);
  --primary-font-color: white;
  --hover-bg: rgba(35, 153, 229, 0.2);
  --light-bg-color: rgba(35, 153, 229, 0.3);
  --dark-bg-color: rgba(35, 153, 229);
  --dark-bg-color-07: rgba(35, 153, 229, 0.7);
  --chat-bubble-color: rgba(35, 153, 229, 0.2);
  --chat-bubble-color-selected: rgba(35, 153, 229, 0.4);
  --primary-err-color: #ff4d4d;
  --std-border: 0;
}

.PatientPanel table {
  width: 100%;
  border-collapse: collapse;
}

.PatientPanel table thead {
  background-color: var(--primary-theme-color);
  color: var(--primary-font-color);
  font-weight: bold;
}

.PatientPanel table thead td {
  padding: 5px;
}

.PatientPanel table i {
  cursor: pointer;
}

.PatientPanel table tbody i {
  color: var(--dark-bg-color);
}

.PatientPanel table tbody tr {
  border-bottom: 1px solid var(--primary-theme-color);
}

.PatientPanel table tbody tr:last-child {
  border-bottom: 0;
  font-weight: bold;
}

.PatientPanel table tbody td:nth-child(1) {
  text-align: left;
  max-width: 200px;
  overflow-wrap: break-word;
}

.PatientPanel table tbody td:nth-child(2) {
  text-align: right;
  min-width: 70px;
}

.PatientPanel .upload-bil,
.PatientPanel .upload-bil.ui.button:focus,
.PatientPanel .upload-bil.ui.button:active {
  background-color: var(--light-bg-color);
  border-color: var(--primary-theme-color);
  border: var(--std-border);
}

.PatientPanel .upload-bil.ui.button:hover {
  background-color: var(--primary-theme-color);
  color: var(--primary-font-color);
  border: var(--std-border);
}

.PatientPanel .send-bil,
.PatientPanel .send-bil.ui.button:focus,
.PatientPanel .send-bil.ui.button:active {
  background-color: var(--dark-bg-color);
  border-color: var(--primary-font-color);
  color: var(--primary-font-color);
  border: var(--std-border);
}

.PatientPanel .send-bil:hover {
  background-color: var(--dark-bg-color-07);
  color: var(--primary-font-color);
  border: var(--std-border);
}

.PatientPanel .ui.segment.expense-segment div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.PatientPanel .ui.segment.expense-segment .ui.header {
  text-align: left;
  margin: 0;
}

.PatientPanel .ui.segment.expense-segment .ui.label {
  align-self: flex-start;
}

.PatientPanel .ui.segment.expense-segment .expense-table-wrapper {
  overflow: auto;
}

.PatientPanel .main-segment {
  border-radius: 0;
  background-color: var(--primary-theme-color);
  height: 100%;
  overflow: auto;
  margin: 0;
}

.PatientPanel .main-segment .patient-info .header {
  color: var(--primary-font-color);
  text-align: center;
}

.PatientPanel .main-segment .patient-info .header.left-align {
  color: var(--primary-font-color);
  text-align: left;
  margin: 5px;
}

.PatientPanel .patient-info i {
  color: var(--primary-font-color);
}

.PatientPanel .ui.segment.expense-segment .expense-table-wrapper table tr,
.PatientPanel .ui.segment.expense-segment .expense-table-wrapper table thead {
  border-bottom: var(--std-border);
}

.PatientPanel .ui.segment.expense-segment .expense-table-wrapper table tr:last-child {
  border: 0;
}
