/* ChatBox.css */

:root{
  --primary-err-color: #FF2929;
  --std-border: 1px solid;
  --hover-bg: rgba(0, 0, 0, 0.1);
  --secondary-bg-color: #F2F2F2;
  --disable-bg-color: #606060;
} 

html[data-theme="penta"]{
  --primary-theme-color: rgba(93,188,210);
  --primary-theme-color-half: rgba(93,188,210, 0.5);
  --primary-theme-color-08: rgba(93,188,210, 0.8);
  --primary-font-color: white;
  --hover-bg: rgba(93,188,210, 0.2);
  --light-bg-color: rgba(93,188,210, 0.3);
  --dark-bg-color: #004884;
  --dark-bg-color-07: rgba(0,42,132, 0.7);
  --std-border: 0;
  --chat-bubble-color: rgba(1, 157, 154, 0.2);
  --chat-bubble-color-selected: rgba(1, 157, 154, 0.6);
}

html[data-theme="penguin"]{
  --primary-theme-color: rgba(35,153,229);
  --primary-theme-color-half: rgba(35,153,229, 0.5);
  --primary-theme-color-08: rgba(35,153,229, 0.8);
  --primary-font-color: white;
  --hover-bg: rgba(35,153,229, 0.2);
  --light-bg-color: rgba(35,153,229, 0.3);
  --dark-bg-color: rgba(35,153,229);
  --dark-bg-color-07: rgba(35,153,229, 0.7);
  --chat-bubble-color: rgba(35,153,229, 0.2);
  --chat-bubble-color-selected: rgba(35,153,229, 0.4);
  --primary-err-color: #FF4D4D;
  --std-border: 0;
}

.ChatBox .messageView{
  height: calc(100vh - 230px);
  overflow-y: auto;
}

.ChatBox .ChatBoxChoice,
.ChatBox .ChatBoxChoice .button{
  background-color: var(--secondary-bg-color);
  color: var(--primary-theme-color);
  height: 40px;
}

.ChatBox .ChatBoxChoice .fields{
  margin: 0;
}

.ChatBox .ChatBoxTopbar .fields{
  justify-content: flex-end;
  margin: 0;
}

.ChatBox .textBox{
  height: 110px;
  border-top: 1px solid var(--secondary-bg-color);
}

.ChatBox .textBox .button{
  vertical-align: middle;
  background-color: var(--primary-theme-color);
  color: black;
}

.ChatBox .textBox textarea{
  height: 100%;
  width: 100%;
}

.ChatBox .textBox textarea.readOnly{
  height: 100%;
  width: 100%;
  background-color: var(--secondary-bg-color);
}

.ChatBox .textBox textarea.readOnly::placeholder{
  color: var(--disable-bg-color);
}

.ChatBox .textBox textarea{ 
  resize: none;
  border: 0;
  padding: 1%;
}

.ChatBox .textBox textarea:focus{
  outline: 0;
}

.ChatBox .textBox .sendButton{
  display: none !important;
}